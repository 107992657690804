
import { myLayer } from './mylayer';
let myOperate = {
    name: "操作",
    toggle(index) {
        if (index === 2) {
            this.changeMin();
        } else {
            this.changeFull();
        }
    },
    // 打开影像图层，全屏比较
    changeFull() {
        document.getElementById("left").style.width = '100%';
        console.log(myLayer);
        myLayer.left._onResize();
    },
    // 关闭影像图层，双屏比较
    changeMin() {
        // this.show=true;
        document.getElementById("left").style.width = '50%';
        myLayer.left._onResize();
    },
};
export { myOperate };
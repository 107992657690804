<!-- remote_sensing -->
<template>
    <div class="remote_sensing_conter">
        <div v-show="!showTbs" class="remote_sensing">
            <!-- 地图容器 -->
            <Mymap
                id="left"
                :myMap.sync="left"
                :mapData="mapInfo"
                mapid="left"
                :fontLayer="ShowMarkLayer"
                :showTool="false"
            >
            </Mymap>
            <Mymap
                id="right"
                :myMap.sync="right"
                :mapData="mapInfo"
                mapid="right"
                :fontLayer="ShowMarkLayer"
                :showTool="false"
            >
            </Mymap>
            <!-- 操作 -->
            <layerOperate @changeOperate="changeOperate"></layerOperate>
            <!-- 影像切换 -->
            <el-select
                v-model="yxNameLeft"
                placeholder="请选择"
                class="select-left"
                @change="changeLeft"
            >
                <el-option
                    v-for="item in layerList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                >
                </el-option>
            </el-select>
            <el-button class="btn-back" size="mini" @click="showTbList">
                返回
            </el-button>
            <el-select
                v-show="operindex === 2"
                v-model="yxNameRight"
                placeholder="请选择"
                class="select-right"
                @change="changeRight"
            >
                <el-option
                    v-for="item in layerList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                >
                </el-option>
            </el-select>
            <!-- 地图操作 -->
            <div class="mapOperate">
                <div
                    v-if="!isFullScreen"
                    class="operateItem"
                    title="全屏展示"
                    @click="emitFullScreen"
                >
                    <i class="el-icon-full-screen"></i>
                </div>
                <div
                    class="operateItem"
                    :class="{ buttonisactive: ShowMarkLayer }"
                    :title="ShowMarkLayer ? '隐藏标注' : '显示标注'"
                    @click="controlLayer($event)"
                >
                    <img src="@image/remote_sensing/layerControl.png" />
                </div>
                <!-- <div class="operateItem fa fa-th-list"
                     :class="{ buttonisactive: showTbs }"
                     title="图斑列表"
                     @click="showTbList"
                >
                </div> -->
            </div>
            <MapDraw
                :basicMap="left"
                :drawConf="drawConf"
                :drawControl.sync="drawControl"
                @updateDraw="updateDraw"
            >
            </MapDraw>
            <!--图斑表单 -->
            <div v-show="markerflag" class="add-marker draggable">
                <h5 class="handle">
                    图斑信息<span @click="close"
                        ><i class="el-icon-close"></i
                    ></span>
                </h5>
                <div class="marker-content">
                    <el-form
                        ref="form"
                        :model="HPF_YGJC_TB"
                        label-width="100px"
                        class="font-style"
                    >
                        <el-row style="border-bottom: 1px solid #ddd">
                            <el-form-item label="图斑编号:">
                                <el-input
                                    @input="toNumber"
                                    v-model="HPF_YGJC_TB.TBBH"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="图斑名称:">
                                <el-input
                                    v-model="HPF_YGJC_TB.TBNAME"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="土地类型:">
                                <input
                                    id="land-types1"
                                    v-model="HPF_YGJC_TB.TDFL"
                                    type="text"
                                    class="landType"
                                    readonly
                                    placeholder="变化前"
                                    @click="showLandType(1)"
                                />
                                --
                                <ul
                                    v-show="showLandTypes"
                                    class="land-types-father scrollbar"
                                >
                                    <li
                                        v-for="(val, index) in landTypes"
                                        :key="index"
                                        @click="
                                            getLandFatherType(
                                                index,
                                                val,
                                                $event,
                                                1
                                            )
                                        "
                                    >
                                        {{ val.name }}
                                        <span
                                            v-if="index !== landTypeIdx"
                                            class="fa fa-plus-square"
                                            @click="
                                                openLandType(1, index, $event)
                                            "
                                        ></span>
                                        <span
                                            v-if="index === landTypeIdx"
                                            class="fa fa-minus-square"
                                            @click="
                                                openLandType(1, index, $event)
                                            "
                                        ></span>
                                        <ul
                                            v-show="index === landTypeIdx"
                                            class="land-types-children"
                                        >
                                            <li
                                                v-for="(
                                                    val1, idx
                                                ) in val.children"
                                                :key="idx"
                                                @click="getLandType(1, val1)"
                                            >
                                                {{ val1 }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <input
                                    id="land-types2"
                                    v-model="HPF_YGJC_TB.TDFLBHH"
                                    type="text"
                                    class="landType"
                                    readonly
                                    placeholder="变化后"
                                    @click="showLandType(2)"
                                />
                                <ul
                                    v-show="showLandTypes2"
                                    class="
                                        land-types-father
                                        land-types-father-after
                                        scrollbar
                                    "
                                >
                                    <li
                                        v-for="(val, index) in landTypes"
                                        :key="index"
                                        @click="
                                            getLandFatherType(
                                                index,
                                                val,
                                                $event,
                                                2
                                            )
                                        "
                                    >
                                        {{ val.name }}
                                        <span
                                            v-if="index !== landTypeIdx2"
                                            class="fa fa-plus-square"
                                            @click="
                                                openLandType(2, index, $event)
                                            "
                                        ></span>
                                        <span
                                            v-if="index === landTypeIdx2"
                                            class="fa fa-minus-square"
                                            @click="
                                                openLandType(2, index, $event)
                                            "
                                        ></span>
                                        <ul
                                            v-show="index === landTypeIdx2"
                                            class="land-types-children"
                                        >
                                            <li
                                                v-for="(
                                                    val1, idx
                                                ) in val.children"
                                                :key="idx"
                                                @click="getLandType(2, val1)"
                                            >
                                                {{ val1 }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </el-form-item>
                            <el-form-item label="图斑面积:">
                                <el-input
                                    v-model="HPF_YGJC_TB.MJ"
                                    @input="
                                        HPF_YGJC_TB.MJ = (HPF_YGJC_TB.MJ.match(
                                            /\d+(\.\d{0,})?/
                                        ) || [''])[0]
                                    "
                                >
                                    <template slot="append">㎡</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="图斑描述:">
                                <el-input
                                    v-model="HPF_YGJC_TB.TBDESC"
                                    class="row-font"
                                    type="textarea"
                                    :autosize="{ minRows: 2, maxRows: 4 }"
                                    placeholder="请输入内容"
                                >
                                </el-input>
                            </el-form-item>
                            <el-form-item
                                label="是否为格局变化:"
                                class="font-sline"
                            >
                                <el-select v-model="HPF_YGJC_TB.ZTGJSFBH">
                                    <el-option value="1" label="是"></el-option>
                                    <el-option value="0" label="否"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item
                                label="是否为新建涉建项目:"
                                class="font-sline"
                            >
                                <el-select v-model="HPF_YGJC_TB.SFXJSJXM">
                                    <el-option value="是"></el-option>
                                    <el-option value="否"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                v-if="HPF_YGJC_TB.SFXJSJXM === '是'"
                                label="新建涉建项目:"
                            >
                                <el-select
                                    v-model="HPF_YGJC_TB.SDLX"
                                    placeholder="请选择新建涉建项目"
                                >
                                    <el-option value="新建"></el-option>
                                    <el-option value="改扩建/重建"></el-option>
                                    <el-option value="情况不符"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-row>
                        <el-form-item label="项目名称:">
                            <el-input v-model="HPF_YGJC_TB.XMMC"></el-input>
                        </el-form-item>
                        <el-form-item label="建筑用途:">
                            <el-select
                                v-model="HPF_YGJC_TB.JZYT"
                                placeholder="请选择建筑用途"
                            >
                                <el-option value="居住建筑"></el-option>
                                <el-option value="行政办公"></el-option>
                                <el-option value="文教建筑"></el-option>
                                <el-option value="科研建筑"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="项目建设状态:">
                            <el-select
                                v-model="HPF_YGJC_TB.XMJSZT"
                                placeholder="请选择项目建设状态"
                            >
                                <el-option value="建设中"></el-option>
                                <el-option value="竣工"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="是否征得文物部门同意:"
                            class="font-sline"
                        >
                            <el-col style="width: 70%">
                                <el-select v-model="HPF_YGJC_TB.SFZDWWBMTY">
                                    <el-option value="是"></el-option>
                                    <el-option value="否"></el-option>
                                </el-select>
                            </el-col>
                            <el-col
                                style="
                                    width: calc(30% - 10px);
                                    margin-left: 10px;
                                "
                            >
                                <el-upload
                                    class="upload-demo remotesensing-upload"
                                    :action="`${BASE_URL}UpLoad/FileSave/`"
                                    :on-success="handleSuccess"
                                    :before-upload="beforeUpload"
                                    multiple
                                    :file-list="fileList"
                                >
                                    <div class="remotesensing-upload actBtn">
                                        点击上传
                                    </div>
                                </el-upload>
                            </el-col>
                            <div
                                v-show="fileList.length === 0"
                                class="el-upload__tip"
                            >
                                只能上传doc、docx、pdf类型文件
                            </div>
                            <template v-if="fileList.length > 0">
                                <div
                                    v-for="(item, index) in fileList"
                                    :key="index"
                                    class="speci_style"
                                >
                                    <ul
                                        class="
                                            el-upload-list el-upload-list--text
                                        "
                                    >
                                        <li
                                            class="
                                                el-upload-list__item
                                                is-success
                                            "
                                        >
                                            <a
                                                class="
                                                    el-upload-list__item-name
                                                "
                                                :href="
                                                    item.response.ResultValue[0]
                                                        .FilePath
                                                "
                                                target="_black"
                                            >
                                                <i class="el-icon-document"></i
                                                >{{ item.name }}
                                            </a>
                                            <label
                                                class="
                                                    el-upload-list__item-status-label
                                                "
                                                ><i
                                                    class="
                                                        el-icon-upload-success
                                                        el-icon-circle-check
                                                    "
                                                ></i
                                            ></label>
                                            <i
                                                class="el-icon-close"
                                                @click="handleRemove(index)"
                                            ></i>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </el-form-item>
                        <el-form-item label="开工时间:">
                            <el-date-picker
                                v-model="HPF_YGJC_TB.KGSJ"
                                value-format="yyyy-MM-dd"
                                type="date"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="竣工时间:">
                            <el-date-picker
                                v-model="HPF_YGJC_TB.JGSJ"
                                value-format="yyyy-MM-dd"
                                type="date"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item label="建筑面积:">
                            <el-input
                                v-model="HPF_YGJC_TB.JZMJ"
                                @input="
                                    HPF_YGJC_TB.JZMJ = (HPF_YGJC_TB.JZMJ.match(
                                        /\d+(\.\d{0,})?/
                                    ) || [''])[0]
                                "
                            >
                                <template slot="append">㎡</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="高度:">
                            <el-input
                                v-model="HPF_YGJC_TB.GD"
                                placeholder="保留1位小数"
                                @input="
                                    HPF_YGJC_TB.GD = (HPF_YGJC_TB.GD.match(
                                        /\d+(\.\d{0,1})?/
                                    ) || [''])[0]
                                "
                            >
                                <template slot="append">m</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="层数:">
                            <el-input
                                v-model="HPF_YGJC_TB.CS"
                                @input="
                                    HPF_YGJC_TB.CS = (HPF_YGJC_TB.CS.match(
                                        /\d+(\.\d{0,})?/
                                    ) || [''])[0]
                                "
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="是否符合管理规定:"
                            class="font-sline"
                        >
                            <el-select v-model="HPF_YGJC_TB.SFFHGLGD">
                                <el-option value="是"></el-option>
                                <el-option value="否"></el-option>
                                <el-option value="无法定保护规划"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="影响评估:">
                            <el-select
                                v-model="HPF_YGJC_TB.PG"
                                placeholder="请选择评估项"
                            >
                                <el-option
                                    v-for="item in TBPGs"
                                    :key="item.VALUE"
                                    :label="item.VALUE"
                                    :value="item.TYPE"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <div class="button-state"></div>
                        </el-form-item>
                    </el-form>
                    <div v-show="HPF_YGJC_TB.SHZT !== '2'" class="btn-save">
                        <template v-if="isCheck">
                            <el-button
                                size="small"
                                type="primary"
                                @click="savePass"
                                >审核通过</el-button
                            >
                            <el-button
                                size="small"
                                type="primary"
                                @click="saveNoPass"
                                >审核不通过</el-button
                            >
                        </template>
                        <template v-if="isEdit">
                            <div class="flex">
                                <div class="actBtn" @click="saveNosubmit">
                                    保存暂不提交
                                </div>
                                <div class="actBtn" @click="saveSubmit">
                                    保存并提交
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <!-- 图斑列表 -->
        <div v-show="showTbs" class="tb-list">
            <h3 class="handle">图斑列表</h3>
            <sn-button
                class="tb_button"
                :snButton="snButton"
                @handleChange="showTbs = false"
            ></sn-button>
            <div class="box_table">
                <el-table
                    id="tb_table"
                    class="scrollbar"
                    :data="tbList"
                    height="100%"
                    highlight-current-row
                    @row-click="selectLayer"
                >
                    <el-table-column
                        prop="TBBH"
                        label="图斑编号"
                    ></el-table-column>
                    <el-table-column
                        prop="TBNAME"
                        label="图斑名称"
                        width="110"
                    ></el-table-column>
                    <el-table-column label="对比年份" width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.YEAR1 }}--{{ row.YEAR2 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="TDFL"
                        label="土地类型（变化前）"
                        width="100"
                    ></el-table-column>
                    <el-table-column
                        prop="TDFLBHH"
                        label="土地类型（变化后）"
                        width="100"
                    ></el-table-column>
                    <el-table-column
                        prop="SDLX"
                        label="新建涉建项目"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        prop="XMMC"
                        label="项目名称"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        prop="JZYT"
                        label="建筑用途"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        prop="XMJSZT"
                        label="项目建设状态"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        prop="SFZDWWBMTY"
                        label="是否征得文物部门同意"
                        width="110"
                    ></el-table-column>
                    <el-table-column
                        prop="KGSJ"
                        label="开工时间"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        prop="JGSJ"
                        label="竣工时间"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        prop="JZMJ"
                        label="建筑面积"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        prop="GD"
                        label="高度"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        prop="CS"
                        label="层数"
                        width="120"
                    ></el-table-column>
                    <el-table-column
                        prop="SFFHGLGD"
                        label="是否符合管理规定"
                        width="140"
                    ></el-table-column>
                    <el-table-column prop="PG" label="评估" width="120">
                        <template slot-scope="{ row }">
                            <span>{{ getValue(row.PG) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                v-show="total"
                layout="total, prev, pager, next"
                :total="total"
                :current-page.sync="currentPage"
                :page-size="10"
                @current-change="getSensingList"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { commonMethods } from "@views/working_desk/monitoring_data/table_config/table-common-methods";
import { myLayer } from "./mylayer";
import { myOperate } from "./myoperate";
import layerOperate from "./layerOperate";
import MapDraw from "@comp/map/mapDraw/MapDraw";
import { mapGetters, mapActions } from "vuex";
import Draggabilly from "draggabilly";
const SHZT = window.SHZT;
export default {
    name: "remote_sensing",
    components: {
        layerOperate,
        MapDraw,
    },
    data() {
        return {
            commonMethods,
            BASE_URL: window.REQUEST_URL,
            snButton: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "returnMap",
                        name: "返回",
                        round: true,
                        backColor: "#ff9066",
                        color: "#fff",
                    },
                ],
            },
            currentPage: 1,
            total: 0,
            isActive: "left",
            left: null, // 左侧地图
            right: null, // 右侧地图
            leftMouse: null, // 左侧鼠标
            rightMouse: null, // 右侧鼠标
            leftMarkLayer: null, // 左侧标注图层
            rightMarkLayer: null, // 右侧标注图层
            ShowMarkLayer: true, // 控制标注图层显影
            yxNameLeft: "", // 左侧选中影像名称
            yxNameRight: "", // 右侧选中影像名称
            layerList: [], // 影像列表
            operindex: 2,
            drawConf: {},
            drawControl: {}, // 标绘组件
            isFullScreen: false,
            TBPGs: [
                {
                    VALUE: "正面影响",
                    TYPE: "0",
                    COLOR: "#A7C49B",
                },
                {
                    VALUE: "兼有正、负影响",
                    TYPE: "2",
                    COLOR: "#D7C49D",
                },
                {
                    VALUE: "负面影响",
                    TYPE: "1",
                    COLOR: "#CD7177",
                },
                {
                    VALUE: "目前尚未确定",
                    TYPE: "3",
                    COLOR: "#B09298",
                },
            ],
            HPF_YGJC_TB: {
                GEOJSON: null,
                YEAR1: null,
                YEAR2: null,
                TBBH: null,
                TBNAME: null,
                TBDESC: null,
                MJ: null,
                TDFL: null,
                TDFLBHH: null,
                PG: null,
                SJWHYCBM: null,
                ZTGJSFBH: null,
                SFXJSJXM: null,
                SDLX: null,
                XMMC: null,
                JZYT: null,
                XMJSZT: null,
                SFZDWWBMTY: null,
                KGSJ: null,
                JGSJ: null,
                JZMJ: null,
                GD: null,
                CS: null,
                SFFHGLGD: null,
            },
            fileList: [],
            showTbs: false,
            showLandTypes: false,
            showLandTypes2: false,
            markerflag: false,
            landTypes: [
                { name: "耕地", children: ["水田", "水浇地", "旱地"] },
                { name: "园地", children: ["果园", "茶园", "其他园地"] },
                { name: "林地", children: ["有林地", "灌木林地", "其他林地"] },
                {
                    name: "草地",
                    children: ["天然牧草地", "人工牧草地", "其他草地"],
                },
                {
                    name: "商服用地",
                    children: [
                        "批发零售用地",
                        "住宿餐饮用地",
                        "商务金融用地",
                        "其他商服用地",
                    ],
                },
                {
                    name: "工矿仓储用地",
                    children: ["工业用地", "采矿用地", "仓储用地"],
                },
                { name: "住宅用地", children: ["城镇住宅用地", "农村宅基地"] },
                {
                    name: "公共管理与公共服务用地",
                    children: [
                        "机关团体用地",
                        "新闻出版用地",
                        "科教用地",
                        "医卫慈善用地",
                        "文体娱乐用地",
                        "公共设施用地",
                        "公园与绿地",
                        "风景名胜设施用地",
                    ],
                },
                {
                    name: "特殊用地",
                    children: [
                        "军事设施用地",
                        "使领馆用地",
                        "监教场所用地",
                        "宗教用地",
                        "殡葬用地",
                    ],
                },
                {
                    name: "交通运输用地",
                    children: [
                        "铁路用地",
                        "公路用地",
                        "街巷用地",
                        "农村道路",
                        "机场用地",
                        "港口码头用地",
                        "管道运输用地",
                    ],
                },
                {
                    name: "水域及水利设施用地",
                    children: [
                        "河流水面",
                        "湖泊水面",
                        "水库水面",
                        "坑塘水面",
                        "沿海滩涂",
                        "内陆滩涂",
                        "沟渠",
                        "水工建筑用地",
                        "冰川及永久积雪",
                    ],
                },
                {
                    name: "其他土地",
                    children: [
                        "空闲地",
                        "设施农业用地",
                        "田坎",
                        "盐碱地",
                        "沼泽地",
                        "沙地",
                        "裸地",
                    ],
                },
            ],
            landTypeIdx: 0, // 土地类型变化前
            landTypeIdx2: 0, // 土地类型变化后
            tbList: [],
            downUrl: "",
            deleting: false, // 是否处于删除状态
            drawnItems: {},
            deleteFJid: [],
            saveIds: [],
            saveing: false, // true 在走保存服务中  false
            isEdit: true,
            isCheck: false,
            mapInfo: {
                baseMapNum: 1,
            },
        };
    },
    computed: {
        ...mapGetters(["heritageId", "userId"]),
        type: function () {
            return this.$route.query.type;
        },
    },
    mounted() {
        this.initLayer();
        this.initLeftLayer();
        this.initMap("left");
        this.initMap("right");
        this.initWmsLayer();
        this.getiamgelist();
        // 监听窗口退出全屏解决无法监听Esc按键
        // eslint-disable-next-line no-undef
        let _this = this;
        window.onresize = () => {
            if (!_this.checkFull()) {
                _this.exitFullscreen();
            }
        };
        let elem = document.querySelector(".draggable");
        let draggie = new Draggabilly(elem, {
            containment: true,
            handle: ".handle",
        });
        this.contralBtn();
        let queryVal = JSON.parse(this.$route.query.val);
        if (queryVal) {
            this.selectLayer(queryVal);
        }
    },
    beforeDestroy() {
        window.onresize = null;
    },
    methods: {
        ...mapActions([
            "GetSensingList",
            "GetSensingJson",
            "GetSensing",
            "SensingAdd", // 图斑新建保存
            "UpdateSensing", // 图斑修改保存
            "SensingDelete", // 图斑删除
            "BatchUpdateSensing",
            "SensingDeleteFile",
            "updateShztOfMonitorData",
            "GetDtList", // 获取地图列表
        ]),
        contralBtn() {
            // type: 0 => 新建;      1 => 编辑;      2 => 查看;      3=> 审核
            // btnType: 0 查看  1 编辑  2 审核
            let btnType =
                this.type === "0" || this.type === "1"
                    ? "1"
                    : this.type === "3"
                    ? "2"
                    : "0";
            this.isEdit = btnType === "1";
            this.isCheck = this.commonMethods.isPower("2") && btnType === "2"; // 有审核权限并且点击审核按钮进入
        },
        getValue(val) {
            let data = this.TBPGs.filter((item) => item.TYPE === val);
            if (data[0]) {
                return data[0].VALUE;
            }
        },
        checkFull() {
            let isFull =
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement;
            if (isFull === undefined) {
                isFull = false;
            }
            return isFull;
        },
        exitFullscreen() {
            this.common.exitFullscreen();
            this.isFullScreen = false;
        },
        emitFullScreen() {
            let mapDom = document.querySelector(".remote_sensing");
            this.common.fullScreen(mapDom);
            this.isFullScreen = true;
        },
        // 鼠标图层初始化
        initLayer: function () {
            let myIcon = L.icon({
                iconUrl: require("@image/remote_sensing/mousemove.png"),
            });
            this.leftMouse = L.marker([0, 0]);
            this.leftMouse.setIcon(myIcon);
            this.rightMouse = L.marker([0, 0]);
            this.rightMouse.setIcon(myIcon);
        },
        // 初始化地图
        initMap: function (dom) {
            let _this = this;
            let code = {
                left: "right",
                right: "left",
            };
            let map = this[dom];
            // 鼠标位置改变   另一半地图鼠标位置相应改变
            map.on("mousemove", function (e) {
                _this.isActive = dom;
                _this[dom].removeLayer(_this[dom + "Mouse"]);
                _this[code[dom]].addLayer(_this[code[dom] + "Mouse"]);
                _this.y = e.latlng.lat.toFixed(4);
                _this.x = e.latlng.lng.toFixed(4);
                _this[code[dom] + "Mouse"].setLatLng(
                    L.latLng([e.latlng.lat, e.latlng.lng])
                );
            });
            // 同步变化
            map.on("moveend", function (e) {
                if (this._container.id === "mapidbox" + _this.isActive) {
                    let newCenter = this.getCenter();
                    let newZoom = this.getZoom();
                    _this[code[dom]].setView(newCenter, newZoom);
                }
                // if(_this.show&&_this.index===3){
                //     _this.rollingCompare();
                // }
            });
            this.drawConf = {
                position: "topleft",
                show: true,
                draw: {
                    polygon: true,
                    polyline: false,
                    rectangle: true,
                },
                edit: {
                    featureGroup: this.drawnItems,
                },
            };
        },
        // 隐藏显示标记图层
        controlLayer(e) {
            this.ShowMarkLayer = !this.ShowMarkLayer;
        },
        // 影像图层初始化
        initWmsLayer() {
            let props = {
                left: this.left,
                right: this.right,
            };
            myLayer.initWmtsLayer(props);
        },
        // 获取影像选择列表
        async getiamgelist() {
            let _this = this;
            // _this.layerList = [
            //     {
            //         "name": "2019",
            //         "layer": "s:ysdyxcg20200113",
            //         'url': window.IMAGE_2019_WMTS_URL
            //     }
            // ];
            // _this.yxNameLeft = _this.layerList[0].name;
            // _this.yxNameRight = _this.layerList[1] ? _this.layerList[1].name : _this.layerList[0].name;

            _this.layerList = [];
            let mapList = await _this.GetDtList();
            if (mapList && mapList.length > 0) {
                mapList.forEach((item) => {
                    if (item.NAME && item.LAYER && item.URL) {
                        let obj = {
                            name: item.NAME,
                            layer: item.LAYER,
                            url: item.URL,
                        };
                        _this.layerList.push(obj);
                    }
                });
            }
            if (_this.HPF_YGJC_TB.YEAR2) {
                _this.yxNameLeft = _this.HPF_YGJC_TB.YEAR2;
            } else {
                if (_this.layerList.length > 0 && _this.layerList[0].name) {
                    _this.yxNameLeft = _this.layerList[0].name;
                } else {
                    _this.yxNameLeft = "";
                }
            }
            if (_this.HPF_YGJC_TB.YEAR1) {
                _this.yxNameRight = _this.HPF_YGJC_TB.YEAR1;
            } else {
                if (_this.layerList.length > 1 && _this.layerList[1].name) {
                    _this.yxNameRight = _this.layerList[1]
                        ? _this.layerList[1].name
                        : _this.layerList[0].name;
                } else if (
                    _this.layerList.length > 0 &&
                    _this.layerList[0].name
                ) {
                    _this.yxNameRight = _this.layerList[0].name;
                } else {
                    _this.yxNameRight = "";
                }
            }

            myLayer.initLayer(_this.layerList);
            _this.getSensing();
            _this.getSensingList();
        },
        getLandFatherType: function (idx, val, e, type) {
            // 获取土地类型父级内容
            let c;
            let s;
            if (type === 1) {
                c = "TDFL";
                s = "showLandTypes";
            }
            if (type === 2) {
                c = "TDFLBHH";
                s = "showLandTypes2";
            }
            if (e.target.tagName === "SPAN") {
                return;
            }
            if ($(e.target).parent()[0].className != "land-types-children") {
                this.HPF_YGJC_TB[c] = val.name;
                this[s] = false;
            }
        },
        getLandType: function (type, da) {
            // 获取土地类型
            let c;
            let s;
            if (type === 1) {
                c = "TDFL";
                s = "showLandTypes";
            }
            if (type === 2) {
                c = "TDFLBHH";
                s = "showLandTypes2";
            }
            this.HPF_YGJC_TB[c] = da;
            this[s] = false;
        },
        changeLeft() {
            myLayer.changeLayer(this.yxNameLeft, "left");
            this.getSensing();
            this.getSensingList();
        },
        changeRight() {
            myLayer.changeLayer(this.yxNameRight, "right");
            this.getSensing();
            this.getSensingList();
        },
        changeOperate(ind) {
            this.operindex = ind;
        },
        showTbList() {
            // 图斑列表的显示与隐藏
            this.$router.push({
                name: "monitoringTable",
                params: {
                    ItemID: "90305",
                    MC: "遥感专项监测",
                },
            });
        },
        updateDraw(type, event) {
            switch (type) {
                case "created": // 新建
                    this.operateCreated(event);
                    break;
                case "deletestart": // 删除开始
                    this.deleting = true;
                    break;
                case "deleted": // 删除完成
                    this.operateDelete(event);
                    break;
                case "edited": // 编辑
                    this.operateEdit(event);
                    break;
                default:
                    break;
            }
            this.saveing = false;
        },
        operateDelete(event) {
            this.deleting = false;
            let idlist = [];
            event.layers.eachLayer((e) => {
                if (e.tbId) {
                    idlist.push(e.tbId);
                }
            });
            this.deleteLayer(idlist.join());
        },
        async operateEdit(event) {
            let idlist = [];
            event.layers.eachLayer((e) => {
                if (e.tbId) {
                    idlist.push({
                        Id: e.tbId,
                        GeoJson: JSON.stringify(e.toGeoJSON()),
                        MJ: L.GeometryUtil.geodesicArea(
                            e.getLatLngs()[0]
                        ).toFixed(2),
                    });
                }
            });
            let res = await this.BatchUpdateSensing({
                jsonstr: JSON.stringify(idlist),
            });
            if (res.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
            }
        },
        operateCreated(event) {
            this.initTbFormData();
            this.HPF_YGJC_TB.GEOJSON = JSON.stringify(event.layer.toGeoJSON());
            this.HPF_YGJC_TB.MJ =
                event.layerType === "marker"
                    ? 0
                    : L.GeometryUtil.geodesicArea(
                          event.layer.getLatLngs()[0]
                      ).toFixed(2);
            this.markerflag = true;
        },
        // 审核通过
        savePass() {
            this.saveSHZT(2);
        },
        // 审核不通过
        saveNoPass() {
            this.saveSHZT(3);
        },
        saveSHZT(zt) {
            this.$confirm("审核提交后不能更改，你确认要提交吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    if (Number(zt) === Number(SHZT.AUDIT_NOT_PASSED)) {
                        this.$prompt("请输入未审核通过原因", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                        })
                            .then(({ value }) => {
                                if (value == null || value.trim().length == 0) {
                                    this.common.showMsg(
                                        "输入无效,请重新审核",
                                        "info"
                                    );
                                } else {
                                    this.updateSjzt(zt, value);
                                }
                            })
                            .catch(() => {
                                this.common.showMsg("已取消", "info");
                            });
                    } else {
                        this.updateSjzt(zt);
                    }
                })
                .catch((e) => {
                    this.common.showMsg("已取消审核", "info");
                });
        },
        async updateSjzt(zt, reason) {
            let res = await this.updateShztOfMonitorData({
                itemid: 90305,
                sjid: this.HPF_YGJC_TB.ID || this.$route.query.id,
                shzt: zt,
                shrid: this.userId || localStorage.getItem("userId"),
                shsj: this.common.getTimeYmdhms(),
                shbtgsm: reason ? reason : "",
            });
            if (res.IsSuccess) {
                this.common.showMsg("审核成功", "success");
                this.showTbList();
            } else {
                this.$alert(res.ResultValue);
            }
        },
        // 保存暂不提交
        saveNosubmit() {
            if (this.saveing) {
                return;
            }
            this.HPF_YGJC_TB.SHZT = "1";
            this.saveTbData();
        },
        // 保存并提交
        saveSubmit() {
            if (this.saveing) {
                return;
            }
            this.HPF_YGJC_TB.SHZT = "0";
            this.saveTbData();
        },
        async saveTbData() {
            this.HPF_YGJC_TB.USERID =
                this.userId || localStorage.getItem("userId");
            (this.HPF_YGJC_TB.glycbtid = this.heritageId),
                (this.HPF_YGJC_TB.RWID = this.$route.query.RWID);
            this.HPF_YGJC_TB.YEAR1 = this.yxNameRight;
            this.HPF_YGJC_TB.YEAR2 = this.yxNameLeft;
            this.HPF_YGJC_TB.SJWHYCBM = this.heritageId;
            let ent = this.HPF_YGJC_TB; // 图斑表信息
            let fjs = this.fileList.filter(
                (item) =>
                    !this.saveIds.includes(item.response.ResultValue[0].Guid)
            );
            let fjids = fjs
                .map((item) => item.response.ResultValue[0].Guid)
                .join();
            if (this.deleteFJid.length > 0) {
                let deletefjs = this.deleteFJid.filter((item) =>
                    this.saveIds.includes(item)
                );
                let deletefjids = deletefjs.join();
                this.SensingDeleteFile({ id: deletefjids });
            }
            this.saveing = true;
            if (this.HPF_YGJC_TB.ID) {
                // 新建-保存图斑Json与图斑信息
                let layerId = this.HPF_YGJC_TB.ID; // 图斑ID
                let res = await this.UpdateSensing({ layerId, ent, fjids });
                if (res && res != null && res !== "" && res !== undefined) {
                    this.$message({
                        type: "success",
                        message: "保存成功",
                    });
                    this.showTbList();
                }
                this.saveing = false;
            } else {
                // 修改-保存图斑信息
                let res = await this.SensingAdd({
                    Geojson: this.HPF_YGJC_TB.GEOJSON,
                    ent,
                    fjids,
                });
                if (res && res != null && res !== "" && res !== undefined) {
                    this.$message({
                        type: "success",
                        message: "保存成功",
                    });
                    this.showTbList();
                }
                this.saveing = false;
            }
        },
        // 删除图斑
        async deleteLayer(val) {
            // 删除图斑
            let _this = this;
            if (val) {
                let res = await _this.SensingDelete({ id: val });
                if (res.IsSuccess) {
                    _this.$message({
                        type: "success",
                        message: "删除成功",
                    });
                } else {
                    _this.$message({
                        type: "error",
                        message: "删除失败",
                    });
                }
            }
        },
        selectLayer(row, event, column) {
            // 选中图斑
            let _this = this;
            _this.setSelectStyle(row.GEOJSON, row.ID, row.USERID);
            _this.addMessage("", row.ID);
            let geo = $.parseJSON(row.GEOJSON);
            let sensing = L.geoJson(geo);
            let bound = sensing.getBounds();
            this.left.fitBounds(bound);
        },
        // 初始化图斑信息数据
        initTbFormData() {
            this.HPF_YGJC_TB = {
                GEOJSON: null,
                YEAR1: null,
                YEAR2: null,
                TBBH: null,
                TBNAME: null,
                TBDESC: null,
                MJ: null,
                TDFL: null,
                TDFLBHH: null,
                PG: null,
                SJWHYCBM: null,
                ZTGJSFBH: null,
                SFXJSJXM: null,
                SDLX: null,
                XMMC: null,
                JZYT: null,
                XMJSZT: null,
                SFZDWWBMTY: null,
                KGSJ: null,
                JGSJ: null,
                JZMJ: null,
                GD: null,
                CS: null,
                SFFHGLGD: null,
            };
        },
        close() {
            // 关闭图斑详细信息模态框
            this.markerflag = false;
            this.fileList = [];
            this.saveIds = [];
            this.initTbFormData();
        },
        beforeUpload(file) {
            let filetyle = file.name.substring(file.name.lastIndexOf("."));
            if (
                filetyle === ".docx" ||
                filetyle === ".doc" ||
                filetyle === ".pdf"
            ) {
                return true;
            } else {
                this.$message.error("只能上传doc、docx、pdf文件!");
                return false;
            }
        },
        handleRemove(index) {
            let data = this.fileList.splice(index, 1);
            if (data[0].response.ResultValue[0].Guid) {
                this.deleteFJid.push(data[0].response.ResultValue[0].Guid);
            }
        },
        handleSuccess(response, file, fileList) {
            this.fileList = fileList;
        },
        // 选中图斑效果
        setSelectStyle(layer, id, user) {
            let _this = this;
            let type;
            _this.selectArea.clearLayers();
            let da;
            let tbid;
            let USERID;
            if (typeof layer === "string") {
                da = JSON.parse(layer);
                tbid = id;
                USERID = user;
                type = da.geometry ? da.geometry.type : da[0].geometry;
            } else {
                da = layer.toGeoJSON();
                tbid = layer.tbId;
                USERID = layer.USERID;
                if (layer.feature) {
                    type = layer.feature.geometry.type;
                }
            }
            if (type != "Point") {
                L.geoJSON(da, {
                    onEachFeature: function (feature, nlayer) {
                        nlayer.tbId = tbid;
                        nlayer.SHZT = layer.SHZT;
                        nlayer.USERID = USERID;
                        nlayer.setStyle({
                            fillColor: "yellow",
                            color: "yellow",
                            fillOpacity: "0.3",
                        });
                        nlayer.on("click", function () {
                            if (!_this.deleting) {
                                _this.setSelectStyle(this);
                                _this.addMessage(this);
                            }
                            if (_this.deleting && nlayer.SHZT == "2") {
                                _this.$message({
                                    type: "warning",
                                    message: "已提交图斑不可删除",
                                });
                            }
                        });
                        _this.selectArea.addLayer(nlayer);
                    },
                    style: function (geoJsonFeature) {
                        return {
                            renderer: L.svg(),
                        };
                    },
                });
            }
        },
        // 获取图斑详细信息
        async addMessage(layer, id) {
            let _this = this;
            _this.HPF_YGJC_TB.YEAR1 = _this.leftYear;
            _this.HPF_YGJC_TB.YEAR2 = _this.rightYear;
            _this.createdLayer = layer;
            let tbId = layer.tbId || id;
            if (tbId && !_this.deleting) {
                _this.markerflag = true;
                let res = await _this.GetSensing({ id: tbId });
                let da = res.ResultValue.ResultValue;
                _this.HPF_YGJC_TB = da.TBXX[0];
                if (!_this.HPF_YGJC_TB) return;
                _this.yxNameLeft = _this.HPF_YGJC_TB.YEAR2;
                _this.yxNameRight = _this.HPF_YGJC_TB.YEAR1;
                _this.changeLeft();
                _this.changeRight();
                _this.showTbs = false;
                da.FJ.forEach(function (item) {
                    let obj = {
                        name: item.WJMC,
                        response: {
                            ResultValue: [
                                {
                                    Guid: item.ID,
                                    FilePath: item.WJLJ,
                                },
                            ],
                        },
                    };
                    _this.fileList.push(obj);
                    _this.saveIds.push(item.ID);
                });
            }
        },
        // 获取图斑列表
        async getSensingList(bhqh, yxpg) {
            let res = await this.GetSensingList({
                sjwhycbm: this.heritageId,
                startYear: this.yxNameLeft,
                endYear: this.yxNameRight,
                bhgqh: "",
                xhzh: "",
                pageIndex: this.currentPage,
                pageSize: 10,
            });
            this.tbList = res.Data;
            this.total = res.Total;
        },
        // 获取图斑地图标绘数据
        async getSensing() {
            let _this = this;
            let res = await _this.GetSensingJson({
                year1: _this.yxNameRight,
                year2: _this.yxNameLeft,
            });
            _this.selectArea.clearLayers();
            _this.sensings.clearLayers();
            _this.drawnItems.clearLayers();
            _this.otherItems.clearLayers();
            let da = res.ResultValue ? res.ResultValue : [];
            let style;
            if (!da || da.length === 0) return;
            for (let i = 0; i < da.length; i++) {
                let geo = $.parseJSON(da[i].GEOJSON);
                if (da[i].SHZT === "2") {
                    style = function (feature) {
                        return { color: "yellow" };
                    };
                } else {
                    style = function (feature) {
                        return { color: "blue" };
                    };
                }
                if (!geo) return;
                /* eslint-disable */
                var sensing = L.geoJson(geo, {
                    selfid: da[i].RKSJ,
                    style: style,
                    onEachFeature: function (feature, layer) {
                        layer.tbId = da[i].ID;
                        layer.SHZT = da[i].SHZT;
                        layer.USERID = da[i].USERID;
                        layer.on("click", function () {
                            if (!_this.deleting) {
                                _this.setSelectStyle(this);
                                if (this._bounds) {
                                    _this.left.fitBounds(this._bounds);
                                }
                                _this.addMessage(this);
                            }
                            if (_this.deleting && this.SHZT == "2") {
                                _this.$message({
                                    type: "warning",
                                    message: "已提交图斑不可删除",
                                });
                            }
                        });
                        if (layer.feature.geometry.type == "Point") {
                            let iconS1 = L.icon({
                                iconUrl: require("@image/map/icon/markerBasic.png"),
                                iconAnchor: [25, 35],
                            });
                            let iconS2 = L.icon({
                                iconUrl: require("@image/map/icon/imarker2-2.png"),
                                iconAnchor: [25, 35],
                            });
                            if (da[i].SHZT === "2") {
                                layer.setIcon(iconS1);
                            } else {
                                layer.setIcon(iconS2);
                            }
                        }
                        //已提交不可编辑
                        if (da[i].SHZT === "2") {
                            _this.otherItems.addLayer(layer);
                        } else {
                            _this.drawnItems.addLayer(layer);
                        }
                    },
                }).addTo(_this.left);
                _this.sensings.addLayer(sensing);
            }
        },
        initLeftLayer: function () {
            //左侧地图图斑初始化
            //可编辑的标绘图层组
            var drawnItems = new L.FeatureGroup();
            this.left.addLayer(drawnItems);
            this.drawnItems = drawnItems;
            //已提交不可编辑的图层组
            var otherItems = new L.FeatureGroup();
            this.left.addLayer(otherItems);
            this.otherItems = otherItems;
            //用户已保存的标绘图层组
            var sensings = new L.FeatureGroup();
            this.left.addLayer(sensings);
            this.sensings = sensings;
            //已删除的标绘图层组
            var deletedLayers = new L.FeatureGroup();
            this.deletedLayers = deletedLayers;
            //画完的标绘图层组
            var selectArea = new L.FeatureGroup();
            this.left.addLayer(selectArea);
            this.selectArea = selectArea;
        },
        showLandType: function (da) {
            //设置土地类型下拉框显示问题
            if (da == 1) {
                this.showLandTypes = !this.showLandTypes;
                this.showLandTypes2 = false;
            }
            if (da == 2) {
                this.showLandTypes2 = !this.showLandTypes2;
                this.showLandTypes = false;
            }
        },
        openLandType: function (type, idx, e) {
            // 打开土地类型树形结构
            let c;
            if (type == 1) {
                c = "landTypeIdx";
            }
            if (type == 2) {
                c = "landTypeIdx2";
            }
            if (e.target.className == "fa fa-plus-square") {
                this[c] = idx;
            } else {
                this[c] = -1;
            }
        },
        toNumber() {
            if (this.HPF_YGJC_TB.TBBH) {
                // int 无特殊值
                this.HPF_YGJC_TB.TBBH = this.HPF_YGJC_TB.TBBH.replace(
                    /[^\d.]/g,
                    ""
                ); // 清除“数字”和“.”以外的字符
                let reg = new RegExp("^(\\d{0," + 8 + "}).*$");
                this.HPF_YGJC_TB.TBBH = this.HPF_YGJC_TB.TBBH.replace(
                    reg,
                    "$1"
                );
                if (this.HPF_YGJC_TB.TBBH != "") {
                    // 整数过滤 整数不允许输入多个0
                    this.HPF_YGJC_TB.TBBH = parseFloat(this.HPF_YGJC_TB.TBBH);
                } else {
                    this.HPF_YGJC_TB.TBBH = "";
                }
            }
        },
    },
};
</script>
<style>
.remote_sensing_conter .upload-demo .el-upload-list {
    display: none;
}
</style>
<style lang="scss" scoped>
.remote_sensing_conter {
    height: 100%;
    position: relative;
    overflow: hidden;
    width: 0;
}
.remote_sensing {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    #left,
    #right {
        float: left;
        width: 50%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #fff;
        position: relative;
    }
    .mapOperate {
        position: absolute;
        // top: 240px;
        // left: 20px;
        top: 340px;
        left: 10px;
        z-index: 1000;
        margin-right: 18px;
        .operateItem {
            width: 30px;
            height: 30px;
            background: #fff;
            text-align: center;
            cursor: pointer;
            line-height: 24px;
        }
        .operateItem:nth-child(1) {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        .operateItem:nth-last-child(1) {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        .operateItem:not(:last-child) {
            border-bottom: 1px solid #ccc;
        }
        .buttonisactive {
            background-color: #ddd;
        }
    }
    .fullScreen {
        position: absolute;
        top: 200px;
        left: 20px;
        z-index: 1000;
        cursor: pointer;
    }
    .select-left {
        position: absolute;
        top: 10px;
        left: 55px;
        z-index: 888;
    }
    .btn-shtg {
        position: absolute;
        top: 18px;
        left: calc(50% - 75px - 56px - 56px);
        z-index: 888;
    }
    .btn-shbtg {
        position: absolute;
        top: 18px;
        left: calc(50% - 75px - 56px);
        z-index: 888;
    }
    .btn-back {
        position: absolute;
        top: 18px;
        left: calc(50% - 75px);
        z-index: 888;
    }
    .select-right {
        position: absolute;
        top: 10px;
        left: calc(50% + 20px);
        z-index: 888;
    }
    .add-marker {
        position: absolute;
        left: 15%;
        margin-left: -190px;
        top: 84px;
        width: 740px;
        z-index: 1000;
        background: #fff;
        border-radius: 4px;
        padding-bottom: 50px;
    }
    .add-marker .marker-content {
        width: 720px;
        padding: 0 10px;
    }
    .marker-content .el-form-item {
        margin-bottom: 10px;
        width: 50%;
        float: left;
    }
    .add-marker h5 {
        color: #fff;
        background: #4ba1a5;
        border-bottom: 1px solid #4ba1a5;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 10px 20px;
        text-align: left;
        color: white;
    }
    .add-marker h5 span {
        float: right;
        cursor: pointer;
        line-height: 21px;
        font-size: 18px;
    }
    .add-marker h5 span:hover {
        color: #ea6052;
    }
    .marker-content .el-input,
    .marker-content .el-textarea {
        width: 250px;
    }
    .marker-content label {
        padding-right: 0px;
        padding-left: 20px;
    }
    .marker-content .landType {
        width: 114px;
        border: 1px solid #bfcbd9;
        height: 40px;
        padding-left: 5px;
        color: #606266;
    }
    .land-types-father {
        position: absolute;
        top: 40px;
        height: 197px;
        overflow-y: auto;
        border: 1px solid #ccc;
        z-index: 1;
        background: #fff;
        padding: 5px 12px 5px 30px;
        width: 140px;
    }
    .land-types-father-after {
        left: 130px;
    }
    .land-types-father li {
        cursor: pointer;
        text-align: left;
        line-height: 20px;
    }
    .land-types-father li:hover {
        color: #23468c;
    }
    .land-types-father > li {
        padding: 3px 0;
        border-bottom: 1px dotted #ccc;
        position: relative;
    }
    .land-types-father > li:last-child {
        border-bottom: none;
    }
    .land-types-children {
        margin-left: 1em;
    }
    .land-types-children li {
        padding: 3px 0;
    }
    .land-types-father .fa {
        position: absolute;
        left: -22px;
        top: 5px;
    }
    .el-row {
        padding-top: 20px;
    }
}
.tb-list {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 20px;
    h3 {
        font-size: 14px;
        line-height: 50px;
        border: 0;
        margin: 0;
        padding: 0;
        font-family: Microsoft yahei, Arial;
        color: #48576a;
        border-collapse: collapse;
        list-style: none;
        background: white;
        text-align: left;
        padding-left: 20px;
        font-size: 16px;
    }
    .close {
        position: absolute;
        right: 10px;
        top: 15px;
        font-size: 20px;
        cursor: default;
    }
    .el-table .cell,
    .el-table th > div {
        padding-left: 0px;
        padding-right: 0px;
    }
    .el-table__header .cell {
        font-weight: normal;
    }
    .box_table {
        margin-top: 20px;
        height: calc(100% - 90px);
        overflow-x: hidden;
        overflow-y: auto;
    }
    .tb_button {
        position: absolute;
        right: 30px;
        top: 25px;
    }
    .el-pagination {
        text-align: left;
        margin-top: 5px;
    }
    .el-pagination .btn-next,
    .el-pagination .btn-prev {
        background: none;
    }
}
.btn-save {
    position: absolute;
    bottom: 10px;
    // left: 50%;
    // transform: translateX(-50%);
    right: 30px;
}
.speci_style {
    display: inline-block;
    width: 100%;
}
.speci_style .el-upload-list__item {
    line-height: 2;
    text-align: left;
}
.speci_style .el-upload-list__item:first-child {
    margin: 0;
}
.el-upload-list__item-name:visited {
    color: #606266;
}

.actBtn {
    background: #186d70;
    color: #fff;
    border-color: #186d70;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid #dcdfe6;
    text-align: center;
    outline: 0;
    margin: 0;
    transition: 0.1s;
    font-weight: 500;
    padding: 12px 14px;
    border-radius: 4px;
    &:nth-child(2) {
        margin-left: 10px;
    }
}
</style>

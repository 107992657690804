let myLayer = {
    name: "图层",
    left: null, // 左侧地图容器
    right: null, // 右侧地图容器
    leftglyMap: null, // 左侧图层组
    rightglyMap: null, // 右侧图层组
    layerList: [], // 影像列表
    LayerLength: 0, // 影像列表长度
    leftLayerArray: [],
    rightLayerArray: [],
    leftLayer: null,
    // 影像图层初始化
    initWmtsLayer(props) {
        this.left = props.left;
        this.right = props.right;
        let leftglyMap = L.layerGroup();
        this.leftglyMap = leftglyMap;
        this.left.addLayer(this.leftglyMap);
        let rightglyMap = L.layerGroup();
        this.rightglyMap = rightglyMap;
        this.right.addLayer(this.rightglyMap);
    },
    // 遗产地影像图层初始化
    initLayer: function (list) {
        this.layerList = list;
        this.leftglyMap.clearLayers();
        this.rightglyMap.clearLayers();
        this.leftLayerArray = [];
        this.rightLayerArray = [];
        let len = this.layerList.length;
        this.LayerLength = len;
        this.layerList.forEach((element, index) => {
            let opacityValueLeft = index == 0 ? 1 : 0;
            let opacityValueRight = index == len - 1 ? 1 : 0;
            let rightIndex = index == 0 ? 1 : (len - index + 1);
            this.setImageLayer(element.name, 'left', len - index, element, opacityValueLeft);
            this.setImageLayer(element.name, 'right', rightIndex, element, opacityValueRight);
        });
    },
    // 设置影像图层
    setImageLayer: function (name, dom, index, imageLayer, opacityValue) {
        // 获得地图服务url
        let reg1 = new RegExp("tilematrix=[0-9]*", "gmi");
        let reg2 = new RegExp("tilerow=[0-9]*", "gmi");
        let reg3 = new RegExp("tilecol=[0-9]*", "gmi");
        let mapurl = imageLayer.url.replace(reg1, "tilematrix={z}").replace(reg2, "tilerow={y}").replace(reg3, "tilecol={x}");
        // 加载地图
        console.log('%c 🥜 mapurl: ', 'font-size:20px;background-color: #3F7CFF;color:#fff;', mapurl);
        let emap_cva = new L.tileLayer(mapurl, {
            crs: L.CRS.EPSG3857,
            zIndex: index
        });
        this[dom + 'glyMap'].addLayer(emap_cva);
        this[dom + 'LayerArray'].push({ name: name, layer: emap_cva });
        emap_cva.setOpacity(opacityValue);
        if (dom == 'left') {
            this.leftLayer = this.leftLayerArray[0].layer;
        }
    },
    // 改变影像图层
    changeLayer: function (name, dom) {
        console.log(name, dom, this[dom + "LayerArray"]);
        this[dom + "LayerArray"].forEach((element, index) => {
            if (element.name == name) {
                // element.layer.setZIndex(_this.LayerLength);
                element.layer.setZIndex(2);
                element.layer.setOpacity(1);
                if (dom == 'left') {
                    this.leftLayer = element.layer;
                }
            } else {
                element.layer.setZIndex(0);
                element.layer.setOpacity(0);
            }
        });
    },
};


export { myLayer };
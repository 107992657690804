<!-- remote_sensing -->
<template>
    <div class="layer-operate">
        <ul class="compare-top">
            <li v-for="(compare,i) in compares" :key="i" :class="{ active: index === i && show }" @click="toggle(i)">
                {{compare}}
            </li>
        </ul>
        <ul class="compare-bottom">
            <li v-show="show && index === 0" style="padding-left: 30px" class="ss remote_sensing_timeoption">
                <span
                    class="triangle"
                    :class="[pflag ? 'el-icon-video-pause' : 'el-icon-video-play']"
                    @click="twinkle($event)"
                >
                </span>
                <el-select v-model="timevalue">
                    <el-option
                        v-for="item in timeoptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </li>
            <div v-show="show && index === 1" class="opacity-bg">
                <el-slider v-model="opacityValue" :format-tooltip="formatTooltip" @input="changOpacity"></el-slider>
            </div>
        </ul>
        <!-- 分割线 -->
        <div v-show="show && index === 3" class="splitLine">
        </div>
        <i v-show="show && index === 3" class="el-icon-rank draggable02"></i>
    </div>
</template>
<script>
import { myLayer } from './mylayer';
import Draggabilly from 'draggabilly';
export default {
    name: 'layerOperate',
    data() {
        return {
            compares: ["闪烁", "透明度", '联动对比', '卷帘对比'],
            show: true,
            index: 2,
            timeoptions: [{
                value: 500,
                label: '0.5s'
            }, {
                value: 1000,
                label: '1s'
            }, {
                value: 2000,
                label: '2s'
            }],
            timevalue: 500,
            pflag: false,
            timer: null,
            opacityValue: 100,
            sliderValue: 0,
            sliderSum: 0,
        };
    },
    watch: {
    },
    mounted() {
        this.initRoll();
        this.$nextTick(() => {
            this.initMove();
        });
    },
    destroyed() {
    },
    methods: {
        toggle(index) {
            this.index = index;
            this.$emit('changeOperate', index);
            if (index === 0) {
                this.pflag = false;
                setOpacityTo1();
            } else {
                this.closeTwinkle();
            }
            if (index === 1) {
                this.opacityValue = 100;
                setOpacityTo1();
            } else {
                this.closeOpacity();
            }
            if (index === 2) {
                this.changeMin();
            } else {
                this.changeFull();
            }
            if (index === 3 && this.show) {
                setOpacityTo1();
                this.startRoll();
            } else {
                this.resetRoll();
            }
            // 闪烁及透明度对比时 设置左侧地图图层全部可见
            function setOpacityTo1() {
                myLayer.leftLayerArray.forEach(element => {
                    element.layer.setOpacity(1);
                });
            }
        },
        initMove() {
            if (myLayer.left) {
                myLayer.left.on('moveend', (e) => {
                    if (this.show && this.index === 3) {
                        this.rollingCompare();
                    }
                });
            }
            if (myLayer.right) {
                myLayer.right.on('moveend', (e) => {
                    if (this.show && this.index === 3) {
                        this.rollingCompare();
                    }
                });  
            }
            
        },
        // 闪烁相关方法 开始
        // 闪烁显示开始
        twinkle(e) {
            let opct = 1;
            this.pflag = !this.pflag;
            if (this.pflag) {
                this.timer = setInterval(function() {
                    opct = opct === 0 ? 1 : 0;
                    myLayer.leftLayer.setOpacity(opct);
                }, this.timevalue);
            } else {
                this.closeTwinkle();
            }
        },
        // 关闭闪烁
        closeTwinkle() {
            if(myLayer.leftLayer) {
                myLayer.leftLayer.setOpacity(1);
            }
            clearInterval(this.timer);
        },
        // 闪烁相关方法 结束
        // 透明度相关方法 开始
        // 改变滑块提示信息数值
        formatTooltip(val) {
            return val;
        },
        // 改变透明度
        changOpacity() {
            let opacity = this.opacityValue / 100;
            if (myLayer.leftLayer) {
                myLayer.leftLayer.setOpacity(opacity);
            }
        },
        // 关闭透明度
        closeOpacity() {
            if (myLayer.leftLayer) {
                myLayer.leftLayer.setOpacity(1);
            }
        },
        // 透明度相关方法 结束
        // 联动对比 开始
        // 打开影像图层，全屏比较
        changeFull() {
            document.getElementById("left").style.width = '100%';
            myLayer.left._onResize();
        },
        // 关闭影像图层，双屏比较
        changeMin() {
            // this.show=true;
            document.getElementById("left").style.width = '50%';
            myLayer.left._onResize();
        },
        // 联动对比 结束
        // 卷帘对比 开始
        // 初始化卷帘
        initRoll() {
            let elem2 = document.querySelector('.draggable02');
            let draggie2 = new Draggabilly(elem2, {
                axis: 'x'
            });
            elem2.style.left = ($('.layer-operate').width() / 2 - 14) + 'px';
            draggie2.on('dragMove', (event, pointer, moveVector) => {
                let value = (draggie2.position.x + 14) / $('#left').width() * 100;
                this.sliderValue = value;
                this.sliderSum = (draggie2.position.x + 14);
                this.rollingCompare();
            });
        },
        // 开始卷帘
        startRoll() {
            this.sliderValue = 50;
            this.sliderSum = $('.layer-operate').width() / 2;
            let elem2 = document.querySelector('.draggable02');
            elem2.style.left = ($('.layer-operate').width() / 2 - 14) + 'px';
            this.rollingCompare();
        },
        // 卷帘对比方法，修改影像图层的边界大小
        rollingCompare() {
            let step = this.sliderValue;
            let nw = myLayer.left.containerPointToLayerPoint([0, 0]);
            let se = myLayer.left.containerPointToLayerPoint(myLayer.left.getSize());
            let clipX = nw.x + (se.x - nw.x) * (step) / 100;
            let rect = 'rect(' + [nw.y, clipX, se.y, nw.x].join('px,') + 'px)';
            myLayer.leftLayer.getContainer().style.clip = rect;
            $('.splitLine').css('left', this.sliderSum + 'px');
        },
        // 结束卷帘
        resetRoll() {
            let nw = myLayer.left.containerPointToLayerPoint([0, 0]);
            let se = myLayer.left.containerPointToLayerPoint(myLayer.left.getSize());
            let rect = 'rect(' + [nw.y, se.x, se.y, nw.x].join('px,') + 'px)';
            myLayer.leftLayer.getContainer().style.clip = rect;
        }
    }
};
</script>
<style lang="scss">
.compare-bottom{
    .el-input__inner{
        height: 26px;
        line-height: 26px;
    }
    .el-input__icon {
        line-height: 26px;
    }
}
</style>
<style lang="scss" scoped>
.layer-operate{
    width: 100%;
    height: 100%;
}
.compare-top{
    z-index: 888;
    top: 18px;
    right: 20px;
    position: absolute;
    li{
        float: left;
        height: 26px;
        background: #fff;
        text-align: center;
        line-height: 26px;
        border-right: 1px solid #ccc;
        width: 60px;
        cursor: pointer;
        color: #48576a;
        font-size: 12px;
    }
    li:first-child{
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    li:last-child{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right: none;
    }
    .active{
        background-color:#c0c4cc;
        color:#303133;
    }
}
.compare-bottom{
    z-index: 888;
    right: 10px;
    position: absolute;
    top: 75px;
    user-select: none;
    li{
        float: left;
        height: 26px;
        background: #fff;
        text-align: center;
        line-height: 26px;
        border-right: 1px solid #ccc;
        position: relative;
        border-radius: 4px;
        .triangle{
            position: absolute;
            left: 6px;
            top: 4px;
            height: 16px;
            font-weight: bold;
            color: #4d90e3;
            font-size: 18px;
            cursor: pointer;
        }
    }
    .opacity-bg{
        position: absolute;
        cursor: pointer;
        width: 239px;
        right: 10px;
        height: 38px;
        padding: 0px 10px;
        background: #fff;
        z-index: 8888;
        border-radius: 5px;
    }
}
.splitLine{
    height: 100%;
    width: 2px;
    background: #FFFF00;
    position: absolute;
    z-index: 999;
    left: 0;
}
.draggable02{
    position: absolute;
    z-index: 999;
    top: 50%;
}
.el-icon-rank{
    font-size: 30px;
    font-weight: bolder;
    color: red;
}
</style>